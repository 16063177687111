import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #004 - Jacob Pressey, Humboldt Regeneration Farm & Brewery"}
    blogUrl={"https://joincrafted.com/004-jacob-pressey-humboldt-regeneration"}
    description={
      "What does it mean to brew 100% house-grown beer? Jacob Pressey of Humboldt Regeneration Brewery & Farm explains."
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/humboldt-regeneration/humboldt_hopdome.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            twitterLink={
              "https://twitter.com/intent/tweet?text=What%20does%20it%20mean%20to%20brew%20100%25%20house-grown%20beer?%20Jacob%20Pressey%20of%20Humboldt%20Regeneration%20Brewery%20%26%20Farm%20explains.%0A%0Ahttps%3A//joincrafted.com/004-jacob-pressey-humboldt-regeneration"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A//joincrafted.com/004-jacob-pressey-humboldt-regeneration"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5"
              }}
            >
              <p>October 5, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table role="presentation" className="document" align="center">
        <tbody>
          <tr>
            <td valign="top">
              {/* Main */}
              <table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                <tbody>
                  <tr className="inner-body">
                    <td>
                      <table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                        <tbody>
                          {/*Main body*/}
                          <tr style={{backgroundColor: '#ffffff'}}>
                            <td className="container">
                              <table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td style={{padding: '5px 5px'}}>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;004 🍻 - Jacob Pressey, Humboldt Regeneration Farm & Brewery</h1>
                                              </div>
                                              </td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>Imagine a world where humankind nurtures and cultivates our land to bring out the best from it, instead of irresponsibly exploiting it.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>It's  a world where you know how  the beer got into your glass. You know the land the grains were grown on - you can taste it. A world where each beer has a unique, local character of its own, one that no other region can replicate.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '16px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                This is the world that <strong>Jacob Pressey is creating at <a href="http://www.humboldtregeneration.com/" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Humbodlt Regeneration Brewery &amp; Farm</a>.</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>Read on to learn more about Jacob's vision and how he's influencing the craft beer industry. You'll find out:
                                                </p>
                                                <ul>
                                                  <li>How craft beer can learn from traditional wineries (don't hate yet, you'll see 😉)</li>
                                                  <li>A Red IPA that defies categorization</li>
                                                  <li>Beerkashi - a way to reuse spent grain, based on the natural Japanese farming technique bokashi</li>
                                                </ul>
                                                <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                                <EmailFormInPage />
                                              </div></td></tr></tbody></table><table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><div style={{textAlign: 'center'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></div></div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} role="presentation">
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/humboldt-regeneration/humboldt_farm_field.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} role="presentation"><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                Image source: https://www.facebook.com/humboldtregeneration.csbfarm</div></td></tr></tbody></table><table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><div style={{textAlign: 'center'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>How'd you get into brewing? What was your journey like from beer drinker, to brewer, to owner?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I grew up in Napa CA and worked in the wine industry as a teenager which gave me cellar experience as well as an interest in fermentation and the machinery and processes of the industry.</p>
                                                <p style={{paddingBottom: '16px'}}>I moved to Humboldt County for college in 2000 to study botany and soil science.  At the time I didn’t think there were many wineries up here but I knew we had a growing craft beer industry.  It took about a year of constantly submitting my resume to the few local breweries in the area 'til I finally got some interviews.  I had a really good letter of recommendation from the winemaker I had worked for and during my interview at Eel River Brewing I got along well with the head brewer and he could tell I had a similar mechanical way of thinking to him.  Luckily for me they were looking for an assistant brewer that they could train to their style of brewing, so I moved up pretty quick and brewed there for about 7 1/2 years.</p>
                                                <p style={{paddingBottom: '16px'}}>I am very happy that I made the transition to craft beer because although I appreciate wine, I find the brewing process much more interesting with so many more variables to play with... and of course I enjoy the product a lot more, I’m almost always in the mood for a beer while wine is more of something for special occasions.</p>
                                                <p style={{paddingBottom: '16px'}}>By the end of my studies at Humboldt State I had become very interested in Permaculture and sustainable food systems, and I had also built my first nano-brew system for playing with beer styles that we didn’t produce at <a href="https://untappd.com/eelriverbrewing" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Eel River</a>.  So, that is when the vision for <a href="https://untappd.com/HumboldtRegeneration" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Humboldt Regeneration Brewery &amp; Farm</a> was born.</p>
                                                <p style={{paddingBottom: '16px'}}>I had the realization that after the industrialization of agriculture it was no longer common for breweries to be directly tied to agriculture like wineries still are today.  The culprit was the malting process had been scaled-up and centralized along with the field crops that supply them.  It took about a year of research and nine months of licensing and permitting, but by August of 2012 I had my first barley harvest, my small floor-malting system was in place, and I finally opened the brewery, operating on a 1 barrel brew system made from old kegs, a few taps, and a home-made growler bottling machine!</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} role="presentation">
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/humboldt-regeneration/humboldt_hopdome.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} role="presentation"><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                The hopdome at Humbodlt
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                Image source: https://www.facebook.com/humboldtregeneration.csbfarm</div></td></tr></tbody></table>
                                                <EmailFormInPage />
                                                <table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What would you call the type of brewery that does everything from grain to glass? Is this what Humboldt Regeneration Brewery does? Is this called a "Farm Brewery"?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I am happy to see the growing category of “Farm Brewery” and since its kind of a re-emerging model I believe it can mean a lot of different things: from serving beer on a farm to growing ingredients, or even just partnering up with a local farm for certain inputs.  All these models are great because they help urge the general public to think about where their beer comes from and maybe even how it was grown.</p><p style={{paddingBottom: '16px'}}>Humboldt Regeneration does focus on the “seed to glass” model and we use at least some local and/or house ingredients in each batch. <strong>And in 2015 our first major milestone was reached.  I brewed my first batch of beer using 100% of my own ingredients grown only 1 mile from the brewery!</strong> The <a href="https://californiacraftbeer.com/" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">California Craft Brewers Association</a> (CCBA) sent out a news release reporting that we were the first California craft brewery to grow, malt, and brew using our own ingredients since prohibition.</p><p style={{paddingBottom: '16px'}}>In the wine industry when a small winery grows most of their own ingredients they can label themselves as an “Estate Winery”, and that is the ultimate goal for Humboldt Regeneration - to become a true “Estate Brewery” growing most of our own ingredients and sourcing ingredients strictly within our bio-region.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is the one beer that you are most proud of? How was this beer born and what makes it special to you?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>It’s hard to pick a favorite beer because I like to brew a lot of different styles, but one beer that I really like and is probably my most popular is the <a href="https://untappd.com/b/humboldt-regeneration-blasphemy-red-jay/1066519" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Blasphemy Red Jay</a>, a West Coast Style Red IPA fermented with my house Belgian yeast.  I love Belgian ales so the first line of recipes I wrote for my nano-brewery were what I call non-traditional Belgian ales, hence the name Blasphemy - some brewers are very traditional and might consider it blasphemy in the beer world to cross style guidelines.</p>
                                                <p style={{paddingBottom: '16px'}}>If I were to broaden this question to the most unique product that I produce, I would have to say my spent grain.  <strong>As a way of reducing waste from the brewing process and to help build healthy soil in the grain fields I ferment all of my spent grain into a very active biological soil amendment that I call “Beerkashi”</strong>, I use the same culturing process as <a href="https://en.wikipedia.org/wiki/Bokashi_(horticulture)" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">bokashi</a> from Japanese Natural Farming.  So the lifecycle of Humboldt Regeneration barley goes from the field to the malt floor and roaster, into the mash, and then back to the field to help feed the soil for future beer.</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} role="presentation">
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/humboldt-regeneration/jacob_pressey_humboldt_regeneration.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} role="presentation"><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                Jacob, running the farm with style
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                Image source:  https://www.facebook.com/humboldtregeneration.csbfarm</div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is the hardest thing you've ever accomplished?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>The hardest thing I have ever done is starting a brewery.  It is difficult to start and run a small business in California, especially if alcohol is involved, and navigating all the hoops necessary to legally produce and sell beer was quite a challenge.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is the mission of Humboldt Regeneration and how do you want it to influence the industry?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>The mission of Humboldt Regeneration Brewery &amp; Farm is to use craft beer as a platform to promote regenerative agriculture and re-localization to the general public. By working toward reducing our sourcing and distribution chains to a local level we hope to create an example business model that not only has a significantly reduced energy, carbon, and waste footprint, but we will also bring the concept of terroir back to the beer world. </p>
                                              <EmailFormInPage />
                                              </div></td></tr></tbody></table><table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><div style={{textAlign: 'center'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>That is an awesome mission: <strong>to bring the concept of terroir back to the beer world</strong>.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>I personally really resonate with this idea. I live in France, a country known for its wine, known by their terroirs. We hear how wine draws its essence from the soil, water, air, etc. of the region. Why not beer? I find it extremely interesting that a product can be so tied to its local environement that it can only be produced in that one place. It becomes a living, evolving thing. Its wonderful.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px', margin: '15px 0'}}>
                                                I praise what <strong>Jacob</strong> is doing at <strong>Humboldt</strong>. May more and more brewing companies follow in the footsteps of the Farm Brewery model that he is pioneering. Brewing is an act of creation and should be a force for good. This is one way to make it so.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} role="presentation"><tbody><tr><td style={{padding: '0px 0px 8px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Let's seek out and support the brewers that are doing stuff locally.</p>
                                                <p style={{paddingBottom: '16px'}}>As always, feel free to let me know what you think, and tell me what you're drinking.</p>
                                                <p style={{paddingBottom: '16px'}}>Take care, until next time 👋</p>
                                                <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                <p style={{paddingBottom: '16px'}}>- Jake</p></div></td></tr></tbody></table></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td></tr></tbody></table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
